export const mainCategoriesPermissions = {
  MAIN_CATEGORIES_VIEW: {
    enabled: ['categories_view', 'categories_edit', 'categories_create', 'categories_delete'],
  },
  MAIN_CATEGORIES_EDIT: {
    enabled: ['categories_edit'],
  },
  MAIN_CATEGORIES_CREATE: {
    enabled: ['categories_create'],
  },
  MAIN_CATEGORIES_DELETE: {
    enabled: ['categories_delete'],
  },
};

import * as React from 'react';
import {useHistory, useParams, useLocation} from 'react-router-dom';
import {
  Box,
  Button,
  Cluster,
  Cover,
  Dialog,
  Divider,
  Heading,
  Icon,
  Paragraph,
  Stack,
  Template,
  useValidateForm,
} from '@pluto-tv/assemble';

import programmingRoutes from 'routes/programming.routes';
import MainCategoriesDetails from './details';
import {IMainCategory} from 'models/mainCategories';
import {mainCategoriesDetailsValidator} from '../validators';
import {useAppPermissions} from 'app/permissions';

export const CHANNEL_TABS = {
  details: 'details',
  channelList: 'channelList',
  vodCollectionList: 'vodCollectionList',
} as const;

export type TChannelTabs = keyof typeof CHANNEL_TABS;

const pathToTabMap = {
  details: CHANNEL_TABS.details,
  'channel-list': CHANNEL_TABS.channelList,
  'vod-collection-list': CHANNEL_TABS.vodCollectionList,
} as const;

const getActiveTab = (pathname: string): TChannelTabs => {
  const nestedPath = pathname.slice(pathname.lastIndexOf('/') + 1) as keyof typeof pathToTabMap;
  if (pathToTabMap[nestedPath]) {
    return CHANNEL_TABS[pathToTabMap[nestedPath]];
  }

  return CHANNEL_TABS.details;
};

const MainCategoryEdit = (): JSX.Element => {
  const {id} = useParams<{id: string}>();
  const location = useLocation();
  const history = useHistory();
  const activeTab: TChannelTabs = getActiveTab(location.pathname);
  const {permissions} = useAppPermissions();
  const [isSaving, setIsSaving] = React.useState(false);
  const [isLeaving, setIsLeaving] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const {
    form,
    model,
    onBlur,
    onChange,
    pristineModel,
    setFields,
    dirtyFields,
    state: formState,
  } = useValidateForm<IMainCategory>([...mainCategoriesDetailsValidator]);

  const navigationTabs = React.useMemo(
    () => [
      {
        key: CHANNEL_TABS.details,
        label: 'Details',
        route: programmingRoutes.paths.mainCategoriesEditDetailsPage,
        component: (
          <MainCategoriesDetails
            key={CHANNEL_TABS.details}
            form={form}
            model={model}
            onBlur={onBlur}
            onChange={onChange}
            pristineModel={pristineModel}
            setFields={setFields}
            dirtyFields={dirtyFields}
          />
        ),
      },
      {
        key: CHANNEL_TABS.channelList,
        label: 'Channel List',
        route: programmingRoutes.paths.mainCategoriesEditChannelList,
        component: <div>Channel List</div>,
      },
      {
        key: CHANNEL_TABS.vodCollectionList,
        label: 'Vod Collection List',
        route: programmingRoutes.paths.mainCategoriesEditVodCollectionList,
        component: <div>Vod Collection List</div>,
      },
    ],
    [dirtyFields, form, model, onBlur, onChange, pristineModel, setFields],
  );

  React.useEffect(() => {
    if (isLeaving) {
      history.push(programmingRoutes.paths.mainCategoriesListPage);
    }
  }, [isLeaving, history]);

  const saveData = React.useCallback(async () => {
    setIsSaving(true);
    // Save data to API here
    try {
    } catch (e) {
    } finally {
      setIsSaving(false);
    }
  }, []);

  const cancelHandler = () => {
    // For conflicting modals
    setIsLeaving(true);
  };

  const handleOnClose = () => {
    setDeleteModalOpen(false);
  };

  const deleteHandler = () => {
    // call Delete API here
    setDeleteModalOpen(false);
  };

  return (
    <>
      <Cover
        scrolling={true}
        gutter='large'
        coverTemplateHeight='100%'
        paddingX={{mobile: 'medium', wide: 'large'}}
        paddingTop={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Stack space='medium'>
            <Cluster growNthChild={1} justify='space-between' align='center' space='medium' wrap={false}>
              <Stack space='xxsmall'>
                <Heading level='h1' truncate={true} truncateBackgroundHover='shadow'>
                  Main Category Test
                </Heading>
                <Cluster align='flex-start' space='xxxxsmall' wrap={false}>
                  <Heading level='h5' color='secondary' whiteSpace='nowrap'>
                    Active Region:
                  </Heading>
                  <Heading level='h5' color='primary'>
                    US
                  </Heading>
                </Cluster>
              </Stack>
              <Cluster space='small' align='center' wrap={false}>
                <Button type='delete' onClick={() => setDeleteModalOpen(true)} permission={permissions.VOD_EDIT}>
                  Delete
                </Button>
              </Cluster>
            </Cluster>
            <Divider color='mist' marginBottom='xxxxxsmall' />
            <Cluster buttonGroup={true} justify='center' wrap={false}>
              {navigationTabs.map(({key, label, route}) => (
                <Button key={key} active={activeTab === key} onClick={() => history.push(route.replace(':id', id))}>
                  {label}
                </Button>
              ))}
            </Cluster>
          </Stack>
        </Template>
        <Template label='cover'>{navigationTabs.map(({key, component}) => activeTab === key && component)}</Template>
        <Template label='footer'>
          <Box background='onyx' paddingX='small' paddingY='small' marginX={{mobile: 'none', wide: 'largeNegative'}}>
            <Cluster justify='space-between'>
              <div></div>
              <Cluster space='xxxsmall'>
                <Button ghost={true} onClick={cancelHandler} id='discard'>
                  Discard
                </Button>
                <Button
                  type='primary'
                  state={!formState.isValid || !formState.isDirty ? 'disabled' : isSaving ? 'thinking' : ''}
                  onClick={saveData}
                  id='save'
                  permission={permissions.MAIN_CATEGORIES_EDIT}
                >
                  Save Changes
                </Button>
              </Cluster>
            </Cluster>
          </Box>
        </Template>
      </Cover>

      <Dialog isOpen={deleteModalOpen} onClose={handleOnClose} width='37.5rem'>
        <Template label='header'>
          <Heading level='h2'>
            <Icon icon='warning' color='warning' space='xxsmall'>
              Are You Sure?
            </Icon>
          </Heading>
        </Template>
        <Template label='body'>
          <Stack space='medium'>
            <Paragraph color='secondary'>
              Are you sure you want to remove the category &apos;Main Category Test&apos;?
            </Paragraph>
          </Stack>
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between'>
            <div></div>
            <Cluster space='small'>
              <Button ghost={true} size='small' onClick={handleOnClose}>
                Cancel
              </Button>
              <Button size='small' type='primary' onClick={deleteHandler}>
                Yes, Proceed
              </Button>
            </Cluster>
          </Cluster>
        </Template>
      </Dialog>
    </>
  );
};

export default MainCategoryEdit;

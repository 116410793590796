import React from 'react';
import {
  Template,
  Heading,
  Stack,
  Cluster,
  Button,
  useValidateForm,
  Dialog,
  FormItem,
  TextInput,
  Select,
} from '@pluto-tv/assemble';
import routes from 'routes/programming.routes';
import {useHistory} from 'react-router-dom';
import {mainCategoriesCreateValidator, IMainCategoriesSearch} from 'views/programming/mainCategories/validators';
import {useUserRegions} from 'helpers/useUserRegions';

interface IMainCategoriesCreateFormProps {
  onCancel: () => void;
  onCreate: (mainCategoryId: string) => void;
  canCreate: boolean;
}

const MainCategoriesCreateForm = React.memo(({onCancel, onCreate}: IMainCategoriesCreateFormProps) => {
  const {activeRegions} = useUserRegions();
  const {model, onChange, form, onBlur, setFields} = useValidateForm<IMainCategoriesSearch>(
    mainCategoriesCreateValidator,
    'immediate',
  );

  const history = useHistory();

  const handleCancel = () => {
    onCancel();
  };

  const handleCreate = async () => {
    const mainCategoryId = '123'; // This should be taken from the endpoint response
    onCreate(mainCategoryId);
    return mainCategoryId;
  };

  const handleCreateAndEdit = async () => {
    const mainCategoryId = await handleCreate();
    if (mainCategoryId) {
      history.push(routes.paths.mainCategoriesEditDetailsPage.replace(':id', mainCategoryId));
    }
  };

  return (
    <Dialog isOpen={true} onClose={() => onCancel()} width='42.8125rem' id='create-hub-form-dialog'>
      <Template label='header'>
        <Heading level='h2'>Add Main Category</Heading>
      </Template>
      <Template label='body'>
        <form
          id='createHubContent'
          onSubmit={ev => {
            ev.preventDefault();
          }}
        >
          <Stack space='small'>
            <FormItem {...form.name} onBlur={() => onBlur('name')}>
              <TextInput
                onChange={value => {
                  onChange('name', value);
                }}
                value={model.name}
              />
            </FormItem>
            <FormItem {...form.activeRegion}>
              <Select
                id='activeRegion'
                clearable={true}
                placeholder='Select Active Region'
                value={
                  {
                    value: model.activeRegion,
                    label: activeRegions.find(ar => ar.code === model.activeRegion)?.name,
                  } as any
                }
                onChange={val => {
                  setFields({
                    activeRegion: val?.value,
                  });
                }}
                options={activeRegions.map(ar => ({
                  label: `${ar.name} (${ar.code})`,
                  value: ar.code,
                }))}
                predicate='value'
              />
            </FormItem>
          </Stack>
        </form>
      </Template>
      <Template label='footer'>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button ghost={true} onClick={handleCancel} id='cancelButton'>
              Cancel
            </Button>
            <Button type='primary' onClick={() => handleCreate()} id='createButton'>
              Create
            </Button>
            <Button type='primary' onClick={handleCreateAndEdit} id='createEditButton'>
              Create and Edit
            </Button>
          </Cluster>
        </Cluster>
      </Template>
    </Dialog>
  );
});

export default MainCategoriesCreateForm;

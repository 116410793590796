import * as React from 'react';
import {Cluster, Heading, Grid, Cover, Template, Box, Button, Paragraph} from '@pluto-tv/assemble';
import {useHistory} from 'react-router-dom';
import programmingRoutes from 'routes/programming.routes';
import contentRoutes from 'routes/content.routes';
import campaignRoutes from 'routes/campaign.routes';
import runLimitsRoutes from 'routes/runLimits.routes';
import adminRoutes from 'routes/admin.routes';
import {useAppPermissions} from 'app/permissions';

export default (): JSX.Element => {
  const history = useHistory();
  const {ableTo} = useAppPermissions();

  const featureBlocks = [
    {
      label: 'Channels',
      enabled: ableTo('CHANNEL_VIEW'),
      description: 'View and manage Channels.',
      route: programmingRoutes.paths.channelListPage,
    },
    {
      label: 'VOD Collections',
      enabled: ableTo('VOD_VIEW'),
      description: 'View and manage VOD Collections.',
      route: programmingRoutes.paths.vodCollectionListPage,
    },
    {
      label: 'Main Categories',
      enabled: ableTo('MAIN_CATEGORIES_VIEW'),
      description: 'View and manage Main Categories.',
      route: programmingRoutes.paths.mainCategoriesListPage,
    },
    {
      label: 'Featured Groups',
      enabled: ableTo('FEATURED_GROUPS_VIEW'),
      description: 'View and manage Featured Groups.',
      route: programmingRoutes.paths.featuredGroupListPage,
    },
    {
      label: 'Hub Manager',
      enabled: ableTo('HUB_VIEW'),
      description: 'View and manage Hub and Carousel configurations.',
      route: programmingRoutes.paths.hubManagerPage,
    },
    {
      label: 'Series',
      enabled: ableTo('SERIES_VIEW'),
      description: 'Create and manage Series.',
      route: contentRoutes.paths.seriesListPage,
    },
    {
      label: 'Episodes',
      enabled: ableTo('EPISODE_VIEW'),
      description: 'View and manage Episodes.',
      route: contentRoutes.paths.episodeListPage,
    },
    {
      label: 'Clips',
      enabled: ableTo('CLIP_VIEW'),
      description: 'Create and manage Clips.',
      route: contentRoutes.paths.clipListPage,
    },
    {
      label: 'Campaigns',
      enabled: ableTo('CAMPAIGN_VIEW'),
      description: 'Create and manage Campaigns and Primetime Carousels.',
      route: campaignRoutes.paths.campaignListPage,
    },
    {
      label: 'Run Limit Tracker',
      enabled: ableTo('RUN_LIMIT_TRACKER_VIEW'),
      description: 'View monthly run count data for licensed titles airing on Pluto TV channels.',
      route: runLimitsRoutes.paths.runLimitTrackerPage,
    },
    {
      label: 'Licensed Titles',
      enabled: ableTo('LICENSED_TITLES_VIEW'),
      description: 'View and manage licensed titles and run limits.',
      route: runLimitsRoutes.paths.licensedTitlesListPage,
    },
    {
      label: 'Roles & Permissions',
      enabled: ableTo('ROLE_VIEW'),
      description:
        'Define new roles and permissions to govern user level access to specific areas and features in the CMS.',
      route: adminRoutes.paths.roleListPage,
    },
    {
      label: 'User Accounts',
      enabled: ableTo('USER_VIEW'),
      description: 'View and manage CMS user accounts.',
      route: adminRoutes.paths.userListPage,
    },
    {
      label: 'Active Regions',
      enabled: ableTo('ACTIVE_REGION_VIEW'),
      description: 'Configure new active regions and territory associations.',
      route: adminRoutes.paths.activeRegionsListPage,
    },
    {
      label: 'App Names',
      enabled: ableTo('APPNAME_VIEW'),
      description: 'Create and manage App Names and Device Type associations.',
      route: adminRoutes.paths.appNameListPage,
    },
  ];

  return (
    <Cover
      scrolling={true}
      gutterTop='medium'
      gutterBottom='large'
      coverTemplateHeight='100%'
      paddingX={{mobile: 'medium', wide: 'large'}}
      paddingTop={{mobile: 'medium', wide: 'large'}}
    >
      <Template label='header'>
        <Cluster justify='space-between' align='center' space='medium'>
          <Heading level='h1'>Directory</Heading>
        </Cluster>
      </Template>
      <Template label='cover'>
        <Box background='pewter' borderTop={true} borderSize='0.125rem' borderColor='cavern' padding='medium'>
          <Grid rowGap='medium' columnGap='large' minimum='23.125rem'>
            {featureBlocks.map(
              ({label, enabled, description, route}, index) =>
                enabled && (
                  <Box
                    key={index}
                    padding='small'
                    borderRadius='0.5rem'
                    borderSize='0.1rem'
                    borderStyle='solid'
                    borderBottom={true}
                    borderTop={true}
                    borderRight={true}
                    borderLeft={true}
                    background='onyx'
                    backgroundHover='cavern'
                  >
                    <Cover gutter='small'>
                      <Template label='header'>
                        <Heading level='h3'>{label}</Heading>
                      </Template>
                      <Template label='cover'>
                        <Paragraph>{description}</Paragraph>
                      </Template>
                      <Template label='footer'>
                        <Button type='primary' onClick={() => history.push(route)}>
                          Go To {label.toUpperCase()}
                        </Button>
                      </Template>
                    </Cover>
                  </Box>
                ),
            )}
          </Grid>
        </Box>
      </Template>
    </Cover>
  );
};

import {getUserPermissions, IPermissionsHook, TUserPermissions, usePermissions} from '@pluto-tv/assemble';

import {channelPermissions} from 'views/programming/channel/permissions';
import {seriesPermissions} from 'views/content/series/permissions';
import {episodePermissions} from 'views/content/episode/permissions';
import {clipPermissions} from 'views/content/clip/permissions';
import {campaignPermissions} from 'views/campaign/permissions';
import {licensedTitlesPermissions} from 'views/runLimits/licensedTitles/permissions';
import {runLimitTrackerPermissions} from 'views/runLimits/runLimitTracker/permissions';
import {rolePermissions} from 'views/admin/role/permissions';
import {userPermissions} from 'views/admin/user/permissions';
import {activeRegionPermissions} from 'views/admin/activeRegions/permissions';
import {appNamePermissions} from 'views/admin/appName/permissions';
import {vodCollectionPermissions} from 'views/programming/vodCollections/permissions';
import {hubManagerPermissions} from 'views/programming/hubManager/permissions';
import {featuredGroupsPermissions} from 'views/programming/featuredGroups/permissions';
import {mainCategoriesPermissions} from 'views/programming/mainCategories/permissions';

export const permissionsMap = {
  ...vodCollectionPermissions,
  ...mainCategoriesPermissions,
  ...hubManagerPermissions,
  ...channelPermissions,
  ...featuredGroupsPermissions,
  ...seriesPermissions,
  ...episodePermissions,
  ...clipPermissions,
  ...campaignPermissions,
  ...licensedTitlesPermissions,
  ...runLimitTrackerPermissions,
  ...rolePermissions,
  ...userPermissions,
  ...activeRegionPermissions,
  ...appNamePermissions,
};

type TPermissions = typeof permissionsMap;

/** Just wrappers for easy TS typings */
export const useAppPermissions = (): IPermissionsHook<TPermissions> => usePermissions<TPermissions>();
export const getAppUserPermissions = (
  userRoles: string[],
  permissionsMap: TPermissions,
): Partial<TUserPermissions<TPermissions>> => getUserPermissions<TPermissions>(userRoles, permissionsMap);

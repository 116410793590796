import * as React from 'react';
import {Button, Cluster, Dialog, Icon, ITableCol, Table, Template, TSize} from '@pluto-tv/assemble';
import {reorderList} from 'helpers/dragAndDrop';
import {isEqual} from 'lodash-es';

interface IReorderPanelProps<T> {
  items: T[];
  onClose: () => void;
  onApplyOrder: (hubCarousels: T[]) => void;
  columns: ITableCol<T>[];
  header: React.ReactNode;
  width?: TSize;
  actions?: boolean;
}

const ReorderPanel = <T extends {id: string}>({
  items,
  columns,
  onClose,
  onApplyOrder,
  header,
  width = '650px',
  actions = false,
}: IReorderPanelProps<T>): JSX.Element => {
  const [list, setList] = React.useState<T[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<T[]>([]);

  React.useEffect(() => {
    setList([...(items || [])]);
  }, [items]);

  const handleApplyOrder = () => {
    onApplyOrder(list);
    onClose();
  };

  const handleDrop = (_from: string, _to: string, fromIndex: number[], toIndex: number) => {
    setList(reorderList(fromIndex, toIndex, list));
  };

  const handleSelect = React.useCallback((rows: T[]) => {
    setSelectedRows(rows);
  }, []);

  const handleMoveUp = React.useCallback(
    (index: number) => {
      setList(reorderList([index], index - 1, list));
    },
    [list],
  );

  const handleMoveDown = React.useCallback(
    (index: number) => {
      setList(reorderList([index], index + 2, list));
    },
    [list],
  );

  const isApplyOrderDisabled = React.useMemo(() => {
    const modelIds = (items || []).map(item => item.id);
    const carouselListIds = (list || []).map(carousel => carousel.id);

    return isEqual(modelIds, carouselListIds);
  }, [list, items]);

  const columnsWithActions = React.useMemo(
    () => [
      ...columns,
      ...(actions
        ? [
            {
              label: 'Actions',
              colWidth: '3.125rem' as TSize,
              transform: (_row: T, _, index: number) => {
                const upDisabled = index === 0 || selectedRows.length > 1;
                const downDisabled = index === list.length - 1 || selectedRows.length > 1;
                return (
                  <Cluster space='xxsmall'>
                    <div className={upDisabled ? 'up-disabled' : ''}>
                      <Icon disabled={upDisabled} onClick={() => handleMoveUp(index)} icon='directionup' />
                    </div>
                    <div className={downDisabled ? 'down-disabled' : ''}>
                      <Icon disabled={downDisabled} onClick={() => handleMoveDown(index)} icon='directiondown' />
                    </div>
                  </Cluster>
                );
              },
            },
          ]
        : ([] as ITableCol<T>[])),
    ],
    [actions, columns, handleMoveDown, handleMoveUp, list.length, selectedRows.length],
  );

  return (
    <Dialog isOpen={true} onClose={onClose} width={width} id='reorderPanel'>
      <Template label='header'>{header}</Template>
      <Template label='body'>
        <Table<T>
          onDrop={handleDrop}
          draggable={true}
          predicate='id'
          onSelect={handleSelect}
          dragKey='listReorder'
          dropKeys={['listReorder']}
          id='listReorder'
          selectable='multiple'
          wrapContent={true}
          cols={columnsWithActions}
          rows={list}
          selected={selectedRows}
        />
      </Template>
      <Template label='footer'>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button id='cancelBtn' ghost={true} size='small' onClick={onClose}>
              Cancel
            </Button>
            <Button
              id='applyOrderBtn'
              type='primary'
              size='small'
              onClick={handleApplyOrder}
              state={isApplyOrderDisabled ? 'disabled' : ''}
            >
              Save Order
            </Button>
          </Cluster>
        </Cluster>
      </Template>
    </Dialog>
  );
};

export default ReorderPanel;

import * as React from 'react';
import {Box, Heading, Select, Status, TdLink} from '@pluto-tv/assemble';
import ReorderPanel from 'components/mainCategoriesList/components/ReorderPanel';
import {MainCategory} from 'components/mainCategoriesList/components/MainCategoriesTable';
import {useUserRegions} from 'helpers/useUserRegions';

interface IMainCategoriesReorderPanelProps {
  items: MainCategory[];
  onClose: () => void;
  onApplyOrder: (hubCarousels: MainCategory[]) => void;
}

const MainCategoriesReorderPanel = ({items, onClose, onApplyOrder}: IMainCategoriesReorderPanelProps): JSX.Element => {
  const {activeRegions} = useUserRegions();
  const [activeRegion, setActiveRegion] = React.useState<string>('US');

  const columns = React.useMemo(
    () => [
      {
        label: 'Order No.',
        transform: (row: MainCategory, _, index: number) => (
          <TdLink
            title={`${index + 1}`}
            row={row}
            onClick={() => {
              alert('clicked!!');
            }}
          />
        ),
      },
      {
        label: 'Display Name',
        transform: (row: MainCategory) => <div>{row.name}</div>,
      },
      {
        label: 'Active Region',
        sortable: true,
        transform: (row: MainCategory) => row.activeRegion?.toUpperCase() || 'N/A',
      },
      {
        label: 'Office Only',
        transform: (row: MainCategory) => (
          <Status label={row.plutoOfficeOnly ? 'Yes' : 'No'} state={row.plutoOfficeOnly ? 'success' : 'neutral'} />
        ),
      },
    ],
    [],
  );

  const header = (
    <>
      <Box width='70%'>
        <Heading level='h2'>Reorder Main Categories</Heading>
      </Box>
      <Box width='30%'>
        <Select
          id='activeRegion'
          clearable={true}
          placeholder='Select Active Region'
          value={
            {
              value: activeRegion,
              label: activeRegions.find(ar => ar.code === activeRegion)?.name,
            } as any
          }
          onChange={val => {
            setActiveRegion(val?.value);
          }}
          options={activeRegions.map(ar => ({
            label: `${ar.name} (${ar.code})`,
            value: ar.code,
          }))}
          predicate='value'
        />
      </Box>
    </>
  );

  return (
    <ReorderPanel
      items={items}
      columns={columns}
      onClose={onClose}
      onApplyOrder={onApplyOrder}
      header={header}
      width='56.25rem'
    />
  );
};

export default MainCategoriesReorderPanel;

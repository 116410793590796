import {IValidatorField} from '@pluto-tv/assemble';
import {IMainCategory} from 'models/mainCategories';

export interface IMainCategoriesSearch {
  name: string;
  activeRegion: string;
  officeOnly?: boolean;
}

export const mainCategoriesDetailsValidator: IValidatorField<IMainCategory>[] = [
  {
    name: 'name',
    label: 'Display Name',
    required: true,
  },
  {
    name: 'slug',
    label: 'Category Slug',
    required: true,
  },
  {
    name: 'activeRegion',
    label: 'Active Region',
    required: true,
  },
  {
    name: 'distribution',
    label: 'Distribution (Limit Category Display to These Devices)',
  },
];

export const mainCategoriesValidator: IValidatorField<IMainCategoriesSearch>[] = [
  {
    name: 'name',
    label: 'Category Name',
  },
  {
    name: 'activeRegion',
    label: 'Active Region',
  },
  {
    name: 'officeOnly',
    label: 'Office Only',
  },
];

export const mainCategoriesCreateValidator: IValidatorField<IMainCategoriesSearch>[] = [
  {
    name: 'name',
    label: 'Category Name',
    required: true,
  },
  {
    name: 'activeRegion',
    label: 'Active Region',
    required: true,
  },
];

import * as React from 'react';
import {
  ContentBox,
  ContentBoxColumn,
  ContentBoxes,
  FormItem,
  Grid,
  ISelectOption,
  Label,
  Select,
  Stack,
  TextInput,
  Toggle,
} from '@pluto-tv/assemble';
import {useAppPermissions} from 'app/permissions';
import {useUserRegions} from 'helpers/useUserRegions';
import {INestedMainCategoriesProps} from '../../nestedPropsInterface';
import {useFindQuery as useFindDevices} from 'features/devices/devicesApi';
import AssetForm from 'components/assetForm';

interface IMainCategoriesProps extends INestedMainCategoriesProps {
  dirtyFields: any;
}

export default ({model, onBlur, onChange, form, setFields}: IMainCategoriesProps): JSX.Element => {
  const {permissions} = useAppPermissions();
  const {activeRegions} = useUserRegions();

  const {data: deviceList} = useFindDevices({
    offset: 0,
    limit: 250,
    sort: 'name:asc',
  });

  const devices = React.useMemo(
    () =>
      Array.from(
        new Set([
          {label: 'ALL', value: 'ALL'},
          ...(deviceList?.data || []).map(d => ({label: d.name, value: d.platform})),
        ]),
      ),
    [deviceList?.data],
  );

  const handleDistributionChange = value => {
    let val = value?.map(val => val.value) || [];
    const prevValuesIncludeAll = model.distribution?.devices?.some(x => x === 'ALL');
    const currValuesIncludeAll = value?.some(x => x.value === 'ALL');

    if (value && value?.length > 1 && prevValuesIncludeAll) {
      val = val?.filter(val => val !== 'ALL');
    } else if (currValuesIncludeAll) {
      val = ['ALL'];
    }

    setFields({distribution: {devices: val, appNames: model.distribution?.appNames || []}});
  };

  return (
    <ContentBoxes layout='columns'>
      <ContentBoxColumn>
        <ContentBox title='About'>
          <Stack space='medium'>
            <FormItem {...form.name} onBlur={() => onBlur('name')} permission={permissions.MAIN_CATEGORIES_EDIT}>
              <TextInput onChange={value => onChange('name', value)} value={model.name} />
            </FormItem>
            <FormItem {...form.slug} onBlur={() => onBlur('slug')} permission={permissions.MAIN_CATEGORIES_EDIT}>
              <TextInput onChange={value => onChange('slug', value)} value={model.slug} />
            </FormItem>
            <FormItem
              {...form.activeRegion}
              onBlur={() => onBlur('activeRegion')}
              permission={permissions.MAIN_CATEGORIES_EDIT}
            >
              <Select
                value={{
                  label: '',
                  value: model.activeRegion,
                }}
                options={activeRegions.map(ar => ({
                  label: `${ar.name} (${ar.code})`,
                  value: ar.code,
                }))}
                onChange={val => {
                  setFields({
                    activeRegion: (val as ISelectOption).value,
                  });
                }}
                predicate='value'
              />
            </FormItem>
            <FormItem
              {...form.distribution}
              onBlur={() => onBlur('distribution')}
              permission={permissions.MAIN_CATEGORIES_EDIT}
            >
              <Select
                value={model.distribution?.devices.map(d => ({label: d.toString(), value: d}))}
                id='deviceDistributionList'
                appendToBody={true}
                predicate='value'
                multiselect={true}
                options={devices}
                searchable={true}
                clearable={true}
                onChange={value => handleDistributionChange(value)}
              />
            </FormItem>

            <Grid gap='xxxlarge'>
              <FormItem label='Office Only' child='Toggle' permission={permissions.MAIN_CATEGORIES_EDIT}>
                <Toggle
                  label='Yes'
                  onChange={value => onChange('plutoOfficeOnly', value)}
                  value={model.plutoOfficeOnly}
                />
              </FormItem>
              <FormItem
                label='Hide Category'
                child='Toggle'
                permission={permissions.MAIN_CATEGORIES_EDIT}
                helpText='Hides display of Main Category to enable special filtering of its content by Content Services.'
                helpTextColor='info'
              >
                <Toggle label='Yes' />
              </FormItem>
            </Grid>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
      <ContentBoxColumn>
        <ContentBox title='Icons'>
          <Stack space='xxlarge'>
            <Grid rowGap='small' columnGap='xlarge' minimum='16.25rem' maxCols={2}>
              <Stack space='medium'>
                <Label>PNG Image</Label>
                <AssetForm
                  assetType='iconpng'
                  asset={model.pngImageURL}
                  contentType='maincategories'
                  contentId={model.id || ''}
                  width='14rem'
                  height='14rem'
                  maxFileSizeKB={2048}
                  minResolutionWidth={150}
                  minResolutionHeight={150}
                  aspectRatioWidth={4}
                  aspectRatioHeight={3}
                  fileTypes={['image/png']}
                  permission={permissions.MAIN_CATEGORIES_EDIT}
                  defaultUrl='https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg'
                  onChange={url => {
                    setFields({pngImageURL: {path: url}});
                  }}
                  showAltText={false}
                />
              </Stack>
              <Stack space='medium'>
                <Label>SVG Image</Label>
                <AssetForm
                  assetType='iconsvg'
                  asset={model.svgImageUrl}
                  contentType='maincategories'
                  contentId={model.id || ''}
                  width='14rem'
                  height='14rem'
                  maxFileSizeKB={2048}
                  minResolutionWidth={150}
                  minResolutionHeight={150}
                  aspectRatioWidth={4}
                  aspectRatioHeight={3}
                  fileTypes={['image/svg+xml']}
                  permission={permissions.MAIN_CATEGORIES_EDIT}
                  defaultUrl='https://images.pluto.tv/assets/images/default/vodcategory.id-imageFeatured.jpg'
                  onChange={url => {
                    setFields({svgImageUrl: {path: url}});
                  }}
                  showAltText={false}
                />
              </Stack>
            </Grid>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
    </ContentBoxes>
  );
};

import {ITableCol, Status, Table, TdLink, TSize} from '@pluto-tv/assemble';
import {TableActions} from 'components/tableActions';
import * as React from 'react';
import routes from 'routes/programming.routes';
import {IMainCategory} from 'models/mainCategories';
import {mainCategoriesRows} from 'components/mainCategoriesList';

export type MainCategory = Pick<IMainCategory, 'name' | 'activeRegion' | 'plutoOfficeOnly'> & {
  id: string;
  hidden: boolean;
};

const MainCategoriesTable = (): JSX.Element => {
  const handleEdit = (id: string) => {
    alert(`Edit ${id}`);
  };

  const columns = React.useMemo(
    () => [
      {
        label: 'Display Name',
        colWidth: '30rem' as TSize,
        sortable: true,
        transform: row => (
          <TdLink row={row} title={row.name} url={routes.paths.mainCategoriesEditChannelList.replace(':id', row.id)} />
        ),
      },
      {
        label: 'Active Region',
        sortable: true,
        transform: row => row.activeRegion?.toUpperCase() || 'N/A',
        colMinWidth: '9.6875rem' as TSize,
      },
      {
        sortable: true,
        label: 'Office Only',
        transform: (row: MainCategory) => (
          <Status label={row.plutoOfficeOnly ? 'Yes' : 'No'} state={row.plutoOfficeOnly ? 'success' : 'neutral'} />
        ),
        colMinWidth: '9.5rem' as TSize,
      },
      {
        sortable: true,
        label: 'Hidden',
        colMinWidth: '9.5rem' as TSize,
        transform: row => <Status label={row.hidden ? 'Yes' : 'No'} state={row.hidden ? 'success' : 'neutral'} />,
      },
      {
        label: 'Actions',
        colWidth: '6.25rem',
        transform: row => (
          <TableActions
            row={row}
            icons={['edit']}
            deleteOption
            altTitle={row.name}
            onClick={(row, icon) => {
              switch (icon) {
                case 'edit':
                  handleEdit(row.id);
                  break;
                default:
              }
            }}
          />
        ),
      } as ITableCol<MainCategory>,
    ],
    [],
  );

  return (
    <Table<MainCategory>
      id='mainCategoriesTable'
      sortDir='asc'
      sortCol='Display Name'
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSort={() => {}}
      virtual
      fixedHeader={true}
      loading={false}
      wrapContent={true}
      lazyLoadingMsg='Loading more items...'
      lazyLoadScrollOffset={5}
      cols={columns}
      rows={mainCategoriesRows}
    />
  );
};

export default MainCategoriesTable;

import * as React from 'react';
import {
  Box,
  Cluster,
  ContentBoxes,
  ContentBox,
  Cover,
  Expand,
  Heading,
  Icon,
  Sidebar,
  Stack,
  Template,
  Button,
} from '@pluto-tv/assemble';
import MainCategoriesTable, {MainCategory} from './components/MainCategoriesTable';
import MainCategoriesSearch, {categoryNameId} from 'components/mainCategoriesList/components/MainCategoriesSearch';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';
import MainCategoriesReorderPanel from 'components/mainCategoriesList/components/MainCategoriesReorderPanel';
import MainCategoriesCreateForm from 'components/mainCategoriesList/components//MainCategoriesCreateForm';

export const mainCategoriesRows: MainCategory[] = [
  {
    id: '1',
    name: 'SpotLight',
    activeRegion: 'US',
    plutoOfficeOnly: false,
    hidden: true,
  },
  {
    id: '2',
    name: 'Featured',
    activeRegion: 'US',
    plutoOfficeOnly: true,
    hidden: false,
  },
  {
    id: '3',
    name: 'Trending',
    activeRegion: 'US',
    plutoOfficeOnly: false,
    hidden: false,
  },
  {
    id: '4',
    name: 'New Releases',
    activeRegion: 'US',
    plutoOfficeOnly: false,
    hidden: false,
  },
  {
    id: '5',
    name: 'Top Picks',
    activeRegion: 'US',
    plutoOfficeOnly: true,
    hidden: true,
  },
  {
    id: '6',
    name: 'Classics',
    activeRegion: 'US',
    plutoOfficeOnly: true,
    hidden: true,
  },
  {
    id: '7',
    name: 'Documentaries',
    activeRegion: 'US',
    plutoOfficeOnly: false,
    hidden: false,
  },
  {
    id: '8',
    name: 'Kids',
    activeRegion: 'US',
    plutoOfficeOnly: true,
    hidden: true,
  },
  {
    id: '9',
    name: 'Sports',
    activeRegion: 'US',
    plutoOfficeOnly: true,
    hidden: false,
  },
  {
    id: '10',
    name: 'Music',
    activeRegion: 'US',
    plutoOfficeOnly: false,
    hidden: false,
  },
  {
    id: '11',
    name: 'News',
    activeRegion: 'US',
    plutoOfficeOnly: false,
    hidden: true,
  },
  {
    id: '12',
    name: 'Lifestyle',
    activeRegion: 'US',
    plutoOfficeOnly: true,
    hidden: true,
  },
];

const MainCategoriesList = React.memo(() => {
  const [searchExpanded, setSearchExpanded] = React.useState(false);
  useToggleSearchBarOnSlash(setSearchExpanded, searchExpanded);
  const [reorderOpen, setReorderOpen] = React.useState<boolean>(false);
  const [createOpen, setCreateOpen] = React.useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleApplyOrder = (items: MainCategory[]) => {
    alert('apply order');
  };

  React.useEffect(() => {
    if (searchExpanded) {
      const categoryNameInput = document.getElementById(categoryNameId);
      categoryNameInput?.focus({
        preventScroll: true,
      });
    }
  }, [searchExpanded]);

  return (
    <>
      <Sidebar fullHeight={true}>
        <Expand width='18.75rem' height='100%' fullHeightContainer={true} isExpanded={searchExpanded}>
          <Template label='expandable'>
            <Box background='pewter' paddingY={'medium'} paddingRight='medium' paddingLeft={'medium'} fullHeight={true}>
              <MainCategoriesSearch
                setIsExpanded={setSearchExpanded}
                onSearch={() => {
                  alert('search');
                }}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClear={() => {}}
              />
            </Box>
          </Template>
        </Expand>
        <Cover
          scrolling={true}
          gutter='medium'
          coverTemplateHeight='100%'
          overflow='auto'
          padding={{mobile: 'medium', wide: 'large'}}
        >
          <Template label='header'>
            <Cluster justify='space-between' align='center' space='medium'>
              <Stack space='xxxsmall'>
                <Cluster align='end' space='small'>
                  <Box paddingBottom='xxxsmall'>
                    <Heading level='h1'>Main Categories</Heading>
                  </Box>
                  <Box paddingBottom='xxxsmall'>
                    <Cluster space='xxsmall' align='center'>
                      <Icon
                        id='expandFilters'
                        icon='tune'
                        space='xxxsmall'
                        verticalAlign='bottom'
                        lineHeight='0px'
                        onClick={() => setSearchExpanded(!searchExpanded)}
                      >
                        {`${mainCategoriesRows.length} Items`}
                      </Icon>
                    </Cluster>
                  </Box>
                </Cluster>
              </Stack>
              <Cluster space='small' align='center'>
                <Button id='reorderBtn' onClick={() => setReorderOpen(true)}>
                  REORDER
                </Button>
                {reorderOpen && (
                  <MainCategoriesReorderPanel
                    items={mainCategoriesRows}
                    onClose={() => setReorderOpen(false)}
                    onApplyOrder={handleApplyOrder}
                  />
                )}
                <Button id='AddMainCatBtn' type='primary' onClick={() => setCreateOpen(true)}>
                  + ADD MAIN CATEGORY
                </Button>
                {createOpen && (
                  <MainCategoriesCreateForm
                    onCancel={() => setCreateOpen(false)}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onCreate={() => {}}
                    canCreate={true}
                  />
                )}
              </Cluster>
            </Cluster>
          </Template>
          <Template label='cover'>
            <Cover gutter='medium' coverTemplateHeight='100%' scrolling={true}>
              <Template label='header'>
                <div></div>
              </Template>
              <Template label='cover'>
                <ContentBoxes layout='cover'>
                  <Template label='contentBoxesCover'>
                    <ContentBox>
                      <Template label='content'>
                        <MainCategoriesTable />
                      </Template>
                    </ContentBox>
                  </Template>
                </ContentBoxes>
              </Template>
            </Cover>
          </Template>
        </Cover>
      </Sidebar>
    </>
  );
});

MainCategoriesList.displayName = 'MainCategoriesList';
export default MainCategoriesList;

import * as React from 'react';
import {
  Button,
  Cluster,
  Cover,
  Divider,
  FormItem,
  Heading,
  Icon,
  Template,
  TextInput,
  Stack,
  useValidateForm,
  Select,
  ISelectOption,
} from '@pluto-tv/assemble';
import {mainCategoriesValidator, IMainCategoriesSearch} from 'views/programming/mainCategories/validators';
import {useUserRegions} from 'helpers/useUserRegions';
// import {useDevicesOptions} from 'views/programming/mainCategories/hooks/useDevices';

const officeOnlyOptions: ISelectOption[] = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
];

export const categoryNameId = 'categoryName';

interface IMainCategoriesSearchProps {
  onSearch: (search: IMainCategoriesSearch) => void;
  onClear: () => void;
  setIsExpanded: (value: boolean) => void;
  favoriteSearch?: React.ReactNode;
}

const MainCategoriesSearch = ({
  onSearch,
  onClear,
  setIsExpanded,
  favoriteSearch,
}: IMainCategoriesSearchProps): JSX.Element => {
  const {activeRegions} = useUserRegions();
  const {model, onChange, form, onBlur, setFields, reset} = useValidateForm<IMainCategoriesSearch>(
    mainCategoriesValidator,
    'immediate',
  );

  const handleSearch = async () => {
    if (model.name || model.activeRegion || model.officeOnly !== undefined) {
      onSearch({
        ...model,
        name: model.name || '',
        activeRegion: model.activeRegion || '',
        officeOnly: model.officeOnly || false,
      });
    }
  };

  const handleClear = async () => {
    reset();
    onClear();
  };

  return (
    <Cover scrolling={true} gutter='medium'>
      <Template label='header'>
        <Stack space='medium'>
          <Cluster align='center' justify='space-between'>
            <Icon icon='tune' space='small' size='large' iconAlign='center'>
              <Heading level='h4'>Search Filters</Heading>
            </Icon>
            <Icon icon='collapseleft' id='closeFilters' size='large' onClick={() => setIsExpanded(false)} />
          </Cluster>
          {favoriteSearch && <>{favoriteSearch}</>}
          <Divider color='graphite' />
        </Stack>
      </Template>
      <Template label='cover'>
        <form
          onSubmit={ev => {
            ev.preventDefault();
            handleSearch();
          }}
        >
          <Stack space='small'>
            <FormItem
              {...form.name}
              onBlur={() => {
                onBlur('name');
              }}
            >
              <TextInput
                id={categoryNameId}
                clearable={true}
                placeholder='Title'
                value={model.name || ''}
                onChange={val => {
                  onChange('name', val);
                }}
              />
            </FormItem>

            <FormItem {...form.activeRegion}>
              <Select
                id='activeRegion'
                clearable={true}
                placeholder='Select Active Region'
                value={
                  {
                    value: model.activeRegion,
                    label: activeRegions.find(ar => ar.code === model.activeRegion)?.name,
                  } as any
                }
                onChange={val => {
                  setFields({
                    activeRegion: val?.value,
                  });
                }}
                options={activeRegions.map(ar => ({
                  label: `${ar.name} (${ar.code})`,
                  value: ar.code,
                }))}
                predicate='value'
              />
            </FormItem>
            <FormItem {...form.officeOnly}>
              <Select
                id='officeOnly'
                clearable={true}
                placeholder='Select Office Only'
                value={officeOnlyOptions.find(oo => oo.value === model.officeOnly) || undefined}
                options={officeOnlyOptions}
                onChange={value => {
                  setFields({
                    officeOnly: value?.value,
                  });
                }}
              />
            </FormItem>
          </Stack>
        </form>
      </Template>
      <Template label='footer'>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button id='clearButton' ghost={true} onClick={handleClear}>
              Clear
            </Button>
            <Button id='searchButton' type='primary' onClick={handleSearch}>
              Search
            </Button>
          </Cluster>
        </Cluster>
      </Template>
    </Cover>
  );
};

export default MainCategoriesSearch;
